var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[(_vm.modalData.modalType === _vm.ModalType.Display)?_c('MemberOrganizationUserModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"organizationId":_vm.modalData.existingItemId},on:{"close":function($event){_vm.modalData.showModal = false}}}):_c('MemberOrganizationModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"mainOffice":_vm.modalData.existingItem},on:{"close":function($event){_vm.modalData.showModal = false},"update":_vm.getAllOrganizations}})],1):_vm._e(),_c('v-form',[_c('BaseTableFiltered',{staticClass:"mt-3",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.offices},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Medlemsorganisasjoner")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk blant organisasjoner","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canCreateMemberOrganization)?_c('BaseTooltipIconButton',{attrs:{"data-cy":"createMemberOrganization","color":"primary","btnText":"Legg til organisasjon","icon":"mdi-plus"},on:{"handleClick":_vm.openNewOrg}}):_vm._e()]},proxy:true},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"data-cy":"viewMemberOrg"},on:{"click":function($event){return _vm.viewMemberOrg(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Vis medlemsorganisasjoner")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.canModifyMemberOrganization)?_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.openEditOrg(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Rediger")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"viewMemberOrgUsers"},on:{"click":function($event){return _vm.viewMemberOrgUsers(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-cog ")])]}}],null,true)},[_c('span',[_vm._v("Vis brukere")])])]}},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Ja" : "Nei")+" ")]}}],null,true)},[_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }